const navbar = document.querySelector(".navbar");
const navbarCollapse = document.querySelector(".navbar .navbar-collapse");
const navbarLinkHome = document.querySelector(".navbar .nav-link-home");
const navbarLinkHowItWorks = document.querySelector(".navbar .nav-link-how-it-works");
const navbarLinkWhatWeDo = document.querySelector(".navbar .nav-link-what-we-do");
const navbarLinkOurOffices = document.querySelector(".navbar .nav-link-our-offices");
const navbarLinkOurRates = document.querySelector(".navbar .nav-link-our-rates");
const navbarLinkFaq = document.querySelector(".navbar .nav-link-faq");
const navbarLinkPaymentProof = document.querySelector(".navbar .nav-link-payment-proof");
const navbarLinkWhatIsSepa = document.querySelector(".navbar .nav-link-what-is-sepa");
const navbarLinkVideos = document.querySelector(".navbar .nav-link-videos");
const navbarLinkPrivacyPolicy = document.querySelector(".nav-link-privacy-policy");
const navbarLinkAmlPolicy = document.querySelector(".nav-link-aml-policy");
const navbarLinkIntegrations = document.querySelector(".nav-link-integrations");
const navbarNavClose = document.querySelector(".nav-close");
const home1 = document.querySelector(".home1");
const home1h1 = document.querySelector(".home1 h1.desktop");
const home1h2 = document.querySelector(".home1 h2.desktop");
const home1h3 = document.querySelector(".home1 h3.desktop");
const home1h1m = document.querySelector(".home1 h1.mobile");
const home1h2m = document.querySelector(".home1 h2.mobile");
const home1h3m = document.querySelector(".home1 h3.mobile");
const homeSocialButtons = document.querySelector(".home1 .social-buttons");
const backTop = document.querySelector(".back-top");
const backTopArrowUp = document.querySelector(".back-top .btn .fa-long-arrow-up");
const backTopArrowDown = document.querySelector(".back-top .btn .fa-long-arrow-down");
const backTopButton = document.querySelector(".back-top .btn");
const home1heroImage = document.querySelector(".home1 .img-hero");
const home2 = document.querySelector(".home2");
const scrollHome2 = document.querySelector(".btn-scroll-home2");
const home2Container = document.querySelector(".home2 .container-step");
const home2Step = document.querySelector(".home2 .img-step");
const home2Step0Text = document.querySelector(".home2 .step0");
const home2Step0Image = document.querySelector(".home2 .img-step0");
const home2Step1 = document.querySelector(".home2 .img-step1");
const home2Step1Text = document.querySelector(".home2 .step1.desktop");
const home2Step1TextMobile = document.querySelector(".home2 .step1.mobile");
const home2Step1TextPara = document.querySelector(".home2 .step1.desktop p");
const home2Step1TextList = document.querySelector(".home2 .step1.desktop ol");
const home2Step1Image = document.querySelector(".home2 .img-step1 img");
const home2Step2 = document.querySelector(".home2 .img-step2");
const home2Step2Text = document.querySelector(".home2 .step2.desktop");
const home2Step2TextMobile = document.querySelector(".home2 .step2.mobile");
const home2Step2TextPara = document.querySelector(".home2 .step2.desktop p");
const home2Step2Image = document.querySelector(".home2 .img-step2 img");
const home2Step3 = document.querySelector(".home2 .img-step3");
const home2Step3Text = document.querySelector(".home2 .step3.desktop");
const home2Step3TextMobile = document.querySelector(".home2 .step3.mobile");
const home2Step3TextPara = document.querySelector(".home2 .step3.desktop p");
const home2Step3Image = document.querySelector(".home2 .img-step3 img");
const home3 = document.querySelector(".home3");
const home3Container = document.querySelector(".home3 .container-step");
const home3CardClose = document.querySelectorAll(".home3 .card .close");
const home3Card1 = document.querySelector(".home3 .card1");
const home3Card1Header = document.querySelector(".home3 .card1 h4");
const home3Card2 = document.querySelector(".home3 .card2");
const home3Card2Header = document.querySelector(".home3 .card2 h4");
//const home3Card3 = document.querySelector(".home3 .card3");
//const home3Card3Header = document.querySelector(".home3 .card3 h4");
//const home3Card4 = document.querySelector(".home3 .card4");
const home4 = document.querySelector(".home4");
const home4Container = document.querySelector(".home4 .container-step");
const home4ContainerDistributionMap = document.querySelector(".home4 .container-step .container-distribution-map");
const home4DistributionMap = document.querySelector(".home4 .container-step .distribution-map");
const home4Map = document.querySelector(".home4 .container-step .map");
const home4Au = document.querySelector(".home4 .map-point.au");
const home4AuTime = document.querySelectorAll(".home4 .au .time");
const home4AuMap = document.querySelector(".home4 .map-au");
const home4AuOffice = document.querySelector(".home4 .office.au");
const home4AuOfficeCity = document.querySelector(".home4 .office.au .city");
const home4AuOfficeClose = document.querySelector(".home4 .office.au .close");
const home4Uk = document.querySelector(".home4 .map-point.uk");
const home4UkTime = document.querySelectorAll(".home4 .uk .time");
const home4UkMap = document.querySelector(".home4 .map-uk");
const home4UkOffice = document.querySelector(".home4 .office.uk");
const home4UkOfficeCity = document.querySelector(".home4 .office.uk .city");
const home4UkOfficeClose = document.querySelector(".home4 .office.uk .close");
const home4Mx = document.querySelector(".home4 .map-point.mx");
const home4MxTime = document.querySelectorAll(".home4 .mx .time");
const home4MxMap = document.querySelector(".home4 .map-mx");
const home4MxOffice = document.querySelector(".home4 .office.mx");
const home4MxOfficeCity = document.querySelector(".home4 .office.mx .city");
const home4MxOfficeClose = document.querySelector(".home4 .office.mx .close");
const home4Co = document.querySelector(".home4 .map-point.co");
const home4CoTime = document.querySelectorAll(".home4 .co .time");
const home4CoMap = document.querySelector(".home4 .map-co");
const home4CoOffice = document.querySelector(".home4 .office.co");
const home4CoOfficeCity = document.querySelector(".home4 .office.co .city");
const home4CoOfficeClose = document.querySelector(".home4 .office.co .close");
const home4Cl = document.querySelector(".home4 .map-point.cl");
const home4ClTime = document.querySelectorAll(".home4 .cl .time");
const home4ClMap = document.querySelector(".home4 .map-cl");
const home4ClOffice = document.querySelector(".home4 .office.cl");
const home4ClOfficeCity = document.querySelector(".home4 .office.cl .city");
const home4ClOfficeClose = document.querySelector(".home4 .office.cl .close");
const home4Br = document.querySelector(".home4 .map-point.br");
const home4BrTime = document.querySelectorAll(".home4 .br .time");
const home4BrMap = document.querySelector(".home4 .map-br");
const home4BrOffice = document.querySelector(".home4 .office.br");
const home4BrOfficeCity = document.querySelector(".home4 .office.br .city");
const home4BrOfficeClose = document.querySelector(".home4 .office.br .close");
const home4CnTime = document.querySelectorAll(".home4 .cn .time");
const home4InTime = document.querySelectorAll(".home4 .in .time");
const home4CnOffice = document.querySelector(".home4 .office.cn");
const home4CnOfficeCity = document.querySelector(".home4 .office.cn .city");
const home4CnOfficeClose = document.querySelector(".home4 .office.cn .close");
const home4InOffice = document.querySelector(".home4 .office.in");
const home4InOfficeCity = document.querySelector(".home4 .office.in .city");
const home4InOfficeClose = document.querySelector(".home4 .office.in .close");
const contentOurRates = document.querySelector(".contents .content.our-rates");
const contentFaq = document.querySelector(".contents .content.faq");
const contentPaymentProof = document.querySelector(".contents .content.payment-proof");
const contentWhatIsSepa = document.querySelector(".contents .content.what-is-sepa");
const contentWhatIsSepaEs = document.querySelector(".contents .content.what-is-sepa-es");
const contentWhatIsSepaFr = document.querySelector(".contents .content.what-is-sepa-fr");
const contentWhatIsSepaIt = document.querySelector(".contents .content.what-is-sepa-it");
const contentVideos = document.querySelector(".contents .content.videos");
const contentPrivacyPolicy = document.querySelector(".contents .content.privacy-policy");
const contentAmlPolicy = document.querySelector(".contents .content.aml-policy");
const contentRequestDemo = document.querySelector(".contents .content.request-demo");
const contentIntegrations = document.querySelector(".contents .content.integrations");
const contentCloseButtons = document.querySelectorAll(".contents .content .close-btn");
const contentLogos = document.querySelectorAll(".contents .content .logo");
const contents = document.querySelectorAll(".contents .content");
const cookiesNotice = document.querySelector(".cookies-notice");
const cookiesNoticeButton = document.querySelector(".cookies-notice .btn");
const demos = document.querySelectorAll(".demo");
const ourRates = document.querySelectorAll(".ourRates");
const demoButton = document.querySelector("#btnDemo");
const demoForm = document.querySelector("#requestDemoForm");
const demoSuccess = document.querySelector(".request-demo-success");
const demoFailed = document.querySelector(".request-demo-failed");

const debounceTime1 = 500;
const debounceTime2 = 5;

// forEach method, could be shipped as part of an Object Literal/Module
var forEach = function (array, callback, scope) {
	for (var i = 0; i < array.length; i++) {
		callback.call(scope, i, array[i]); // passes back stuff we need
	}
};
//debounce func
const debounce = (func, delay) => {
	let inDebounce
	return function () {
		const context = this
		const args = arguments
		clearTimeout(inDebounce)
		inDebounce = setTimeout(() => func.apply(context, args), delay)
	}
}

//throttle func
const throttle = (func, limit) => {
	let inThrottle
	return function () {
		const args = arguments
		const context = this
		if (!inThrottle) {
			func.apply(context, args)
			inThrottle = true
			setTimeout(() => inThrottle = false, limit)
		}
	}
}

//Cookie
const getCookie = function (cname) {
	const name = cname + "=";
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

const removeHash = function() {
	history.pushState("", document.title, window.location.pathname
		+ window.location.search);
}

const checkCookie = function(){
	setTimeout(function () {
		if (getCookie("cookiesNoticeOk") != "true") {
			cookiesNotice.style.display = "block";
		}
	}, 1000);
}

cookiesNoticeButton.addEventListener("click", function (event) {
	document.cookie = "cookiesNoticeOk=true;path=/";
	cookiesNotice.style.display = "none";
});
checkCookie();

//Nav 
const goToNav = function () {
	const nav = window.location.hash;
	
	if (!!nav){		
		const height1 = home2Container.clientHeight;
		const height2 = home2Container.clientHeight;
		const height3 = home3Container.clientHeight;
		const widht4 = home4Container.clientWidth;
		const height4 = home4Container.clientHeight;

		if (nav.toLocaleLowerCase() == "#what-we-do") {
			window.scrollTo({
				top: height1 + height3 * 1.8,
				left: 0,
				behavior: 'smooth'
			});
		} else if (nav.toLocaleLowerCase() == "#how-it-works") {
			window.scrollTo({
				top: height1 + height3 * 2.8 + height2 * 1.6,
				left: 0,
				behavior: 'smooth'
			});
		} else if (nav.toLocaleLowerCase() == "#our-offices") {
			if (widht4 >= 960){
				window.scrollTo({
					top: height1 + height2 * 2.8 + height3 * 2.8 + 10,
					left: 0,
					behavior: 'smooth'
				});
			} else {
				window.scrollTo({
					top: height1 + height2 * 2.8 + height3 * 2.8 + 10,
					left: 0,
					behavior: 'smooth'
				});
			}
		} else if (nav.toLocaleLowerCase() == "#home") {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		} else if (nav.toLocaleLowerCase() == "#our-rates") {
			contentOurRates.classList.add("open");
		} else if (nav.toLocaleLowerCase() == "#faq") {
			contentFaq.classList.add("open");
		}
		else if (nav.toLocaleLowerCase() == "#what-is-sepa") {
			contentWhatIsSepa.classList.add("open");
		}
		else if (nav.toLocaleLowerCase() == "#what-is-sepa-es") {
			contentWhatIsSepaEs.classList.add("open");
		}
		else if (nav.toLocaleLowerCase() == "#what-is-sepa-fr") {
			contentWhatIsSepaFr.classList.add("open");
		}
		else if (nav.toLocaleLowerCase() == "#what-is-sepa-it") {
			contentWhatIsSepaIt.classList.add("open");
		}
		else if (nav.toLocaleLowerCase() == "#payment-proof") {
			contentPaymentProof.classList.add("open");
		}
		else if (nav.toLocaleLowerCase() == "#videos") {
			contentVideos.classList.add("open");
		} else if (nav.toLocaleLowerCase() == "#privacy-policy") {
			contentPrivacyPolicy.classList.add("open");
		} else if (nav.toLocaleLowerCase() == "#aml-policy") {
			contentAmlPolicy.classList.add("open");
		} else if (nav.toLocaleLowerCase() == "#request-demo") {
			contentRequestDemo.classList.add("open");
			demoForm.style.display = "flex";
			demoFailed.style.display = "none";
			demoSuccess.style.display = "none";
		} else if (nav.toLocaleLowerCase() == "#integrations") {
			contentIntegrations.classList.add("open");
		}
	}
	return false;
}
goToNav();
function hashHandler() {
	this.NavClicked = false;
	this.oldHash = window.location.hash;
	this.Check;

	var that = this;
	var detect = function () {
		if (that.oldHash != window.location.hash || that.NavClicked) {
			that.NavClicked = false;
			that.oldHash = window.location.hash;
			goToNav();
		} 
	};
	this.Check = setInterval(function () { detect() }, 100);
}
var hashDetection = new hashHandler();

navbarLinkHome.addEventListener("click", function (event) {
	hashDetection.NavClicked = true;
	navbarCollapse.classList.remove("show");
});
navbarLinkHowItWorks.addEventListener("click", function (event) {
	hashDetection.NavClicked = true;
	navbarCollapse.classList.remove("show");
});
navbarLinkWhatWeDo.addEventListener("click", function (event) {
	hashDetection.NavClicked = true;
	navbarCollapse.classList.remove("show");
});
navbarLinkOurOffices.addEventListener("click", function (event) {
	hashDetection.NavClicked = true;
	navbarCollapse.classList.remove("show");
});

navbarLinkOurRates.addEventListener("click", function (event) {
	hashDetection.NavClicked = true;
	navbarCollapse.classList.remove("show");
});

navbarLinkFaq.addEventListener("click", function (event) {
	hashDetection.NavClicked = true;
	navbarCollapse.classList.remove("show");
});

navbarLinkFaq.addEventListener("click", function (event) {
	hashDetection.NavClicked = true;
	navbarCollapse.classList.remove("show");
});

navbarLinkWhatIsSepa.addEventListener("click", function (event) {
	hashDetection.NavClicked = true;
	navbarCollapse.classList.remove("show");
});

navbarLinkPaymentProof.addEventListener("click", function (event) {
	hashDetection.NavClicked = true;
	navbarCollapse.classList.remove("show");
});

navbarLinkVideos.addEventListener("click", function (event) {
	hashDetection.NavClicked = true;
	navbarCollapse.classList.remove("show");
});

navbarLinkPrivacyPolicy.addEventListener("click", function (event) {
	hashDetection.NavClicked = true;
	navbarCollapse.classList.remove("show");
});

navbarLinkAmlPolicy.addEventListener("click", function (event) {
	hashDetection.NavClicked = true;
	navbarCollapse.classList.remove("show");
});

navbarLinkIntegrations.addEventListener("click", function (event) {
	hashDetection.NavClicked = true;
	navbarCollapse.classList.remove("show");
});

navbarNavClose.addEventListener("click", function (event) {
	hashDetection.NavClicked = true;
	navbarCollapse.classList.remove("show");
});

forEach(demos, function (index2, value) {
	value.addEventListener("click", function (event) {
		hashDetection.NavClicked = true;
		navbarCollapse.classList.remove("show");
	});
});

forEach(ourRates, function (index2, value) {
	value.addEventListener("click", function (event) {
		hashDetection.NavClicked = true;
		navbarCollapse.classList.remove("show");
	});
});

forEach(contentCloseButtons, function (index, value) {
	value.addEventListener("click", function (event) {
		forEach(contents, function (index2, value2) {
			value2.classList.remove("open");
		});
	});
});

forEach(contentLogos, function (index, value) {
	value.addEventListener("click", function (event) {
		forEach(contents, function (index2, value2) {
			value2.classList.remove("open");
		});
	});
});

// Scoll Homepage
const backTopArrow = function () {
	const scrollTop = window.scrollY;
	const page1Bottom = home1.clientHeight;
	const page2Bottom = home2.clientHeight;
	const page3Bottom = home3.clientHeight;
	const page4Bottom = home4.clientHeight;
	if (scrollTop > page1Bottom + page2Bottom + page3Bottom ) {
		backTopArrowUp.style.display = "block";
		backTopArrowDown.style.display = "none";
	} else {
		backTopArrowUp.style.display = "none";
		backTopArrowDown.style.display = "block";
	}
}
backTopArrow();
document.addEventListener("scroll", function(event) {

	//reset location on scroll 
	
	const scrollTop = window.scrollY;
	const page1Bottom = home1.clientHeight;
	const opacity1 = (scrollTop / page1Bottom);
	home3.style.opacity = Math.log2(1.9 * opacity1);
	const page2Bottom = home1.clientHeight + home3.clientHeight;
	const opacity2 = (scrollTop / page2Bottom);
	home2.style.opacity = Math.log2(1.9 * opacity2);
	const page3Bottom = home1.clientHeight + home2.clientHeight + home3.clientHeight;
	const opacity3 = (scrollTop / page3Bottom);
	if (home3.clientWidth <= 960){
		home4.style.opacity = opacity3;
	} else {
		home4.style.opacity = Math.log2(1.9 * opacity3);
	}

	//BackToTop Button
	if (scrollTop > page1Bottom) {
		backTopButton.classList.remove("btn-secondary");
		backTopButton.classList.add("btn-primary");	
	} else {
		backTopButton.classList.remove("btn-primary");
		backTopButton.classList.add("btn-secondary");
	}

	//Nav Bar
	if (scrollTop > page2Bottom) {
		navbar.classList.remove("navbar-dark");
		navbar.classList.add("navbar-light");
		navbar.classList.remove("bg-white");
	} else {
		navbar.classList.add("navbar-dark");
		navbar.classList.remove("navbar-light");
		navbar.classList.remove("bg-white");
	}
	if (scrollTop > page3Bottom) {
		navbar.classList.add("bg-white");
	} else {
		navbar.classList.remove("bg-white");
	}

	//NavItem
	const height1 = home2Container.clientHeight;
	const height2 = home2Container.clientHeight;
	const height3 = home3Container.clientHeight;
	const widht4 = home4Container.clientWidth;
	const height4 = home4Container.clientHeight;

	if (scrollTop < height1){
		navbarLinkHome.classList.add("active");
		navbarLinkHowItWorks.classList.remove("active");
		navbarLinkWhatWeDo.classList.remove("active");
		navbarLinkOurOffices.classList.remove("active");
	} else if (scrollTop < height1 + height3 * 2.8) {
		navbarLinkHome.classList.remove("active");
		navbarLinkHowItWorks.classList.remove("active");
		navbarLinkWhatWeDo.classList.add("active");
		navbarLinkOurOffices.classList.remove("active");
	} else if (scrollTop >= height1 + height3 * 2.8 + height2 * 2.8 + height4 * 0.4 && widht4 < 960) {
		navbarLinkHome.classList.remove("active");
		navbarLinkHowItWorks.classList.remove("active");
		navbarLinkWhatWeDo.classList.remove("active");
		navbarLinkOurOffices.classList.add("active")
	} else if (scrollTop < height1 + height2 * 2.8 + height3 * 1.6) {
		navbarLinkHome.classList.remove("active");
		navbarLinkHowItWorks.classList.add("active");
		navbarLinkWhatWeDo.classList.remove("active");
		navbarLinkOurOffices.classList.remove("active");	
	} else if (scrollTop >= height1 + height2 * 2.8 + height3 * 2.8) {
		navbarLinkHome.classList.remove("active");
		navbarLinkHowItWorks.classList.remove("active");
		navbarLinkWhatWeDo.classList.remove("active");
		navbarLinkOurOffices.classList.add("active")		
	} 

	backTopArrow();
});

//Home 1
// Text Position on Home 1
const home1TextPosition = function() {
	const home1heroImageW = home1heroImage.width/2;
	const home1heroImageH = home1heroImage.height/2;
	
	home1h1.style.opacity = 1;
	home1h1.style.marginLeft =  (-1.3 * home1heroImageW) + "px";
	home1h1.style.marginTop = -0.4 * home1heroImageH +"px";
	home1h1.style.fontSize = home1heroImageH / 5 + "px";
	home1h1.style.lineHeight = 1.3 * home1heroImageH / 5 + "px";

	home1h2.style.opacity = 1;
	home1h2.style.marginLeft =  (-1.15 * home1heroImageW) + "px";
	home1h2.style.marginTop = (-0.6 * home1heroImageH ) + "px";
	home1h2.style.fontSize = home1heroImageH / 7 + "px";
	
	home1h3.style.opacity = 1;
	home1h3.style.marginTop = (1.1 * home1heroImageH) + "px";
	home1h3.style.marginLeft = (home1heroImageW) + "px";
	home1h3.style.width = 2* home1heroImageH + "px";
	home1h3.style.fontSize = home1heroImageH / 12 + "px";

	if (home1heroImageH > 200){
		homeSocialButtons.style.opacity = 1;
		homeSocialButtons.style.marginLeft = (-1.75 * home1heroImageW) + "px";
	} else {
		homeSocialButtons.style.opacity = 0;
	}
	

	backTop.style.opacity = 1;
	backTop.style.marginLeft = (-1.75 * home1heroImageW) + "px";
}
const home1TextPositionMobile = function () {
	const home1heroImageW = home1heroImage.width / 2;
	const home1heroImageH = home1heroImage.height / 2;

	home1h1m.style.opacity = 1;
	home1h1m.style.marginLeft = "-65px";
	home1h1m.style.marginTop = home1heroImageH/ 2.8 + "px";
	home1h1m.style.fontSize = home1heroImageH / 5 + "px";
	home1h1m.style.lineHeight = 1.4* home1heroImageH / 5 + "px";

	home1h2m.style.opacity = 1;
	home1h2m.style.marginLeft = "-35px";;
	home1h2m.style.marginTop = "40px";
	home1h2m.style.fontSize = home1heroImageH / 7 + "px";

	home1h3m.style.opacity = 1;
	home1h3m.style.marginTop = home1heroImageH * 2.05 + "px";
	home1h3m.style.fontSize = home1heroImageH / 12 + "px";
}

window.addEventListener("resize", debounce(home1TextPosition, debounceTime1));
window.addEventListener("orientationchange", debounce(home1TextPosition, debounceTime1));
window.addEventListener("resize", debounce(home1TextPositionMobile, debounceTime1));
window.addEventListener("orientationchange", debounce(home1TextPositionMobile, debounceTime1));
const heroImage = new Image();
heroImage.src = home1heroImage.src;
heroImage.onload = () => {
	home1TextPosition();
	home1TextPositionMobile()
}


//scroll to Section
scrollHome2.addEventListener("click", function(){
	const top2 = home2.offsetTop;
	const top3 = home3.offsetTop;
	const top4 = home4.offsetTop;
	const left2 = home2.offsetLeft;
	const left3 = home3.offsetLeft;
	const left4 = home4.offsetLeft;
	const width2 = home2Container.offsetWidth;
	const height1 = home2Container.clientHeight;
	const height2 = home2Container.clientHeight;
	const height3 = home3Container.clientHeight;
	const height4 = home4Container.clientHeight;
	const scrollToTop = window.scrollY;
	const page1Bottom = home1.clientHeight;
	const page2Bottom = home2.clientHeight;
	const page3Bottom = home3.clientHeight;
	
	if (scrollToTop < page1Bottom + (height2)){
		window.scrollTo({
			top: height1 + height3 * 1.8,
			left: left2,
			behavior: 'smooth'
		});
	} else if (scrollToTop < page1Bottom + page2Bottom - 0.2 * height2){
		window.scrollTo({
			top: height1 + height3 * 2.8 + height2 * 1.6,
			left: left2,
			behavior: 'smooth'
		});
	} else if (scrollToTop < page1Bottom + page2Bottom + page3Bottom - 0.2 * height4) {
		window.scrollTo({
			top: height1 + height2 * 2.8 + height3 * 2.8 + 10,
			left: left2,
			behavior: 'smooth'
		});
	} else  {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}
	
});

//Home 2
const home2StepImageSize = function () {
	home2Step.style.width = "";
	home2Step.style.height = "";
	const home2StepW = home2Step.clientWidth + "px";
	const home2StepH = home2Step.clientHeight + "px";
	const home1Height = home1.clientHeight;
	const home3Height = home3.clientHeight;
	let imgSize = home2StepH;
	if (home2StepW < imgSize )
		imgSize = home2StepW;
	
	home2Step.style.width = imgSize;
	home2Step.style.height = imgSize;
	home2Step1Image.style.width = imgSize;
	home2Step1Image.style.height = imgSize;
	home2Step2Image.style.width = imgSize;
	home2Step2Image.style.height = imgSize;
	home2Step3Image.style.width = imgSize;
	home2Step3Image.style.height = imgSize;
	
	const scrollTop = window.scrollY;
	const containerHeight = home2Container.clientHeight;
	const containerWidth = home2Container.clientWidth;
	const animationLimit = 0.1 * containerHeight + home1Height + home3Height;
	
	if (scrollTop > animationLimit){
		const percStep1 = (scrollTop - animationLimit) / containerHeight;
		const percStep2 = (scrollTop - animationLimit - containerHeight/2) * 2 / containerHeight;
		const percStep3 = (scrollTop - animationLimit - containerHeight) * 2 / containerHeight;
		const hideMargin = -300;
		const hideMargin2 = -300;
		const hideMarginBottom = -7;
		if (percStep1 > 0 && percStep1 <= 1) {
			home2Step1.style.height = (percStep1 * 100) + "%";
			home2Step1TextMobile.style.opacity = percStep1;
			home2Step1TextPara.style.marginLeft = (hideMargin * (1- percStep1)) + "%";
			home2Step1TextList.style.marginLeft = (hideMargin * (1 - percStep1)) + "%";
		} else if (percStep1 > 1) {
			home2Step1.style.height = "100%";
			home2Step1TextMobile.style.opacity = 1;
			home2Step1TextPara.style.marginLeft = "0";
			home2Step1TextList.style.marginLeft = "0";
		} else if (percStep1 < 0) {
			home2Step1.style.height = "0%";
			home2Step1TextMobile.style.opacity = 0;
			home2Step1TextPara.style.marginLeft = hideMargin + "%";
			home2Step1TextList.style.marginLeft = hideMargin + "%";
		}
		if (percStep2 > 0 && percStep2 <= 1) {
			home2Step2.style.width = (percStep2 * 100) + "%";
			home2Step2TextMobile.style.opacity = percStep2;
			home2Step2Text.style.bottom = (hideMarginBottom * (1 - percStep2)) + "rem";
		} else if (percStep1 > 1) {
			home2Step2.style.width = "100%";
			home2Step2TextMobile.style.opacity = 1;
			home2Step2Text.style.bottom = "3.5rem";
		} else if (percStep2 < 0) {
			home2Step2.style.width = "0%";
			home2Step2TextMobile.style.opacity = 0;
			home2Step2Text.style.bottom = hideMarginBottom +  "rem";
		}
		if (percStep3 > 0 && percStep3 <= 1) {
			home2Step3.style.width = (percStep3 * 100) + "%";
			home2Step3TextMobile.style.opacity = percStep3;
			home2Step3TextPara.style.marginRight = (hideMargin2 * (1 - percStep3)) + "%";
		} else if (percStep3 > 1) {
			home2Step3.style.width = "100%";
			home2Step3TextMobile.style.opacity = 1;
			home2Step3TextPara.style.marginRight = "0";
		} else if (percStep3 < 0) {
			home2Step3.style.width = "0%";
			home2Step3TextMobile.style.opacity = 0;
			home2Step3TextPara.style.marginRight = hideMargin2 + "%";
		}
	}
}

window.addEventListener("resize", debounce(home2StepImageSize, debounceTime1));
window.addEventListener("orientationchange", debounce(home2StepImageSize, debounceTime1));
window.addEventListener("scroll", home2StepImageSize);
const stepImage = new Image();
stepImage.src = home2Step0Image.src;
stepImage.onload = () => {
	home2StepImageSize();
}

//Home 3
const home3Animation = function () {
	const scrollTop = window.scrollY;
	const containerHeight = home3Container.clientHeight;
	const home1Height = home1.clientHeight;
	const home2Height = home2Container.clientHeight;
	//const animationLimit = 0.1 * containerHeight + home1Height + home2Height;
	const animationLimit = 0.1 * home1Height;
	
	if (scrollTop > animationLimit) {
		let percStep = (scrollTop - animationLimit) / containerHeight;
		let percStep1 = percStep;
		if (percStep1 > 1 )
			percStep1 = 1;
		else if (percStep1 < 0)
			percStep1 = 0;

		let percStep2 = percStep - 0.4;
		if (percStep2 > 1)
			percStep2 = 1;
		else if (percStep2 < 0)
			percStep2 = 0;

		let percStep3 = percStep - 0.6;
		if (percStep3 > 1)
			percStep3 = 1;
		else if (percStep3 < 0)
			percStep3 = 0;

		let percStep4 = percStep - 0.8;
		if (percStep4 > 1)
			percStep4 = 1;
		else if (percStep2 < 0)
			percStep4 = 0;
		
		
		home3Card1.style.opacity = percStep1;
		home3Card2.style.opacity = percStep2;
		//home3Card3.style.opacity = percStep3;
		//home3Card4.style.opacity = percStep4;
		
		home3Card1.style.marginTop = 0 + ((1 - percStep1) * 200) + "px";
		home3Card2.style.marginTop = 64 + ((1 - percStep2) * 300) + "px";
		//home3Card3.style.marginTop = 128 + ((1 - percStep3) * 400) + "px";
		//home3Card4.style.marginTop = 192 + ((1 - percStep4) * 500) + "px";
	}

}
window.addEventListener("resize", debounce(home3Animation, debounceTime1));
window.addEventListener("orientationchange", debounce(home3Animation, debounceTime1));
window.addEventListener("scroll", home3Animation);
home3Card1Header.addEventListener("click", function (event) {
	home3Card1.classList.add("opened");
	home3Card2.classList.remove("opened");
	//home3Card3.classList.remove("opened");
	//home3Card4.classList.remove("opened");
});
home3Card2Header.addEventListener("click", function (event) {
	home3Card1.classList.remove("opened");
	home3Card2.classList.add("opened");
	//home3Card3.classList.remove("opened");
	//home3Card4.classList.remove("opened");
});
/*
home3Card3Header.addEventListener("click", function (event) {
	home3Card1.classList.remove("opened");
	home3Card2.classList.remove("opened");
	home3Card3.classList.add("opened");
	//home3Card4.classList.remove("opened");
});
*/

forEach(home3CardClose, function (index, value) {
	value.addEventListener("click", function(){
		home3Card1.classList.remove("opened");
		home3Card2.classList.remove("opened");
		//home3Card3.classList.remove("opened");
	});
});

/*
home3Card4.addEventListener("click", function (event) {
	home3Card1.classList.remove("opened");
	home3Card2.classList.remove("opened");
	home3Card3.classList.remove("opened");
	//home3Card4.classList.toggle("opened")
});
*/
//Home 4
const AuTime = function () {
	forEach(home4AuTime, function (index2, value2) {
		value2.innerHTML = moment().tz('Australia/Sydney').format('LT');
	});
	setTimeout(AuTime, 60000);
}
AuTime();
const UkTime = function () {
	forEach(home4UkTime, function (index2, value2) {
		value2.innerHTML = moment().tz("Europe/London").format('LT');
	});
	setTimeout(UkTime, 60000);
}
UkTime();
const MxTime = function () {
	forEach(home4MxTime, function (index2, value2) {
		value2.innerHTML = moment().tz("America/Mexico_City").format('LT');
	});
	setTimeout(MxTime, 60000);
}
MxTime();
const CoTime = function () {
	forEach(home4CoTime, function (index2, value2) {
		value2.innerHTML = moment().tz("America/Bogota").format('LT');
	});
	setTimeout(CoTime, 60000);
}
CoTime();
const ClTime = function () {
	forEach(home4ClTime, function (index2, value2) {
		value2.innerHTML = moment().tz("America/Santiago").format('LT');
	});
	setTimeout(ClTime, 60000);
}
ClTime();
const BrTime = function () {
	forEach(home4BrTime, function (index2, value2) {
		value2.innerHTML = moment().tz("America/Sao_Paulo").format('LT');
	});
	setTimeout(BrTime, 60000);
}
BrTime();
const CnTime = function () {
	forEach(home4CnTime, function (index2, value2) {
		value2.innerHTML = moment().tz("Asia/Shanghai").format('LT');
	});
	setTimeout(CnTime, 60000);
}
CnTime();
const InTime = function () {
	forEach(home4InTime, function (index2, value2) {
		value2.innerHTML = moment().tz("Asia/Kolkata").format('LT');
	});
	setTimeout(InTime, 60000);
}
InTime();


const NoMap = function () {
	home4AuMap.style.opacity = 0;
	home4UkMap.style.opacity = 0;
	home4MxMap.style.opacity = 0;
	home4CoMap.style.opacity = 0;
	home4ClMap.style.opacity = 0;
	home4BrMap.style.opacity = 0;
}

const AuMap = function () {
	home4AuMap.style.opacity = 1;
	home4UkMap.style.opacity = 0;
	home4MxMap.style.opacity = 0;
	home4CoMap.style.opacity = 0;
	home4ClMap.style.opacity = 0;
	home4BrMap.style.opacity = 0;
}
const UkMap = function () {
	home4AuMap.style.opacity = 0;
	home4UkMap.style.opacity = 1;
	home4MxMap.style.opacity = 0;
	home4CoMap.style.opacity = 0;
	home4ClMap.style.opacity = 0;
	home4BrMap.style.opacity = 0;
}
const MxMap = function () {
	home4AuMap.style.opacity = 0;
	home4UkMap.style.opacity = 0;
	home4MxMap.style.opacity = 1;
	home4CoMap.style.opacity = 0;
	home4ClMap.style.opacity = 0;
	home4BrMap.style.opacity = 0;
}
const CoMap = function () {
	home4AuMap.style.opacity = 0;
	home4UkMap.style.opacity = 0;
	home4MxMap.style.opacity = 0;
	home4CoMap.style.opacity = 1;
	home4ClMap.style.opacity = 0;
	home4BrMap.style.opacity = 0;
}
const ClMap = function () {
	home4AuMap.style.opacity = 0;
	home4UkMap.style.opacity = 0;
	home4MxMap.style.opacity = 0;
	home4CoMap.style.opacity = 0;
	home4ClMap.style.opacity = 1;
	home4BrMap.style.opacity = 0;
}
const BrMap = function () {
	home4AuMap.style.opacity = 0;
	home4UkMap.style.opacity = 0;
	home4MxMap.style.opacity = 0;
	home4CoMap.style.opacity = 0;
	home4ClMap.style.opacity = 0;
	home4BrMap.style.opacity = 1;
}
home4Au.addEventListener("mouseover", function (event) {
	AuMap();
});
home4Au.addEventListener("mouseout", function (event) {
	NoMap();
});
home4Uk.addEventListener("mouseover", function (event) {
	UkMap();
});
home4Uk.addEventListener("mouseout", function (event) {
	NoMap();
});
home4Mx.addEventListener("mouseover", function (event) {
	MxMap();
});
home4Mx.addEventListener("mouseout", function (event) {
	NoMap();
});
home4Co.addEventListener("mouseover", function (event) {
	CoMap();
});
home4Co.addEventListener("mouseout", function (event) {
	NoMap();
});
home4Cl.addEventListener("mouseover", function (event) {
	ClMap();
});
home4Cl.addEventListener("mouseout", function (event) {
	NoMap();
});
home4Br.addEventListener("mouseover", function (event) {
	BrMap();
});
home4Br.addEventListener("mouseout", function (event) {
	NoMap();
});

const home4Animation = function () {
	const scrollTop = window.scrollY;
	const containerHeight = home4Container.clientHeight;
	const containerWidth = home4Container.clientWidth;
	const home1Height = home1.clientHeight;
	const home2Height = home2.clientHeight;
	const home3Height = home3Container.clientHeight;
	const home4ContainerDistributionMapWidth = home4ContainerDistributionMap.clientWidth;
	const home4ContainerDistributionMapHeight = home4ContainerDistributionMap.clientHeight;
	
	home4DistributionMap.style.width = (home4ContainerDistributionMapHeight*1700/1100) + "px";
	home4DistributionMap.style.height = home4ContainerDistributionMapHeight + "px";
	
	const animationLimit = 0.2 * containerHeight +  home1Height + home2Height + home3Height;
	if (scrollTop > animationLimit) {
		let percStep = (scrollTop - animationLimit) / containerHeight;
		let percStep1 = percStep - 0.6;
		if (percStep1 > 1)
			percStep1 = 1;
		else if (percStep1 < 0)
			percStep1 = 0;

		let percStep2 = percStep - 0.6;
		if (percStep2 > 1)
			percStep2 = 1;
		else if (percStep2 < 0)
			percStep2 = 0;

		let percStep3 = percStep - 0.6;
		if (percStep3 > 1)
			percStep3 = 1;
		else if (percStep3 < 0)
			percStep3 = 0;

		let percStep4 = percStep - 0.6;
		if (percStep4 > 1)
			percStep4 = 1;
		else if (percStep2 < 0)
			percStep4 = 0;	

		home4Uk.style.opacity = percStep1;
		home4Mx.style.opacity = percStep2;
		home4Co.style.opacity = percStep2;
		home4Cl.style.opacity = percStep2;
		home4Br.style.opacity = percStep3;
		home4Au.style.opacity = percStep4;

		home4Uk.style.marginTop = 0 + ((1 - percStep1) * -350) + "px";
		home4Mx.style.marginTop = 0 + ((1 - percStep2) * -300) + "px";
		home4Co.style.marginTop = 0 + ((1 - percStep2) * -250) + "px";
		home4Cl.style.marginTop = 0 + ((1 - percStep2) * -250) + "px";
		home4Br.style.marginTop = 0 + ((1 - percStep3) * -200) + "px";
		home4Au.style.marginBottom = 0 + ((1 - percStep4) * 150) + "px";
	}

}
window.addEventListener("resize", debounce(home4Animation, debounceTime1));
window.addEventListener("orientationchange", debounce(home4Animation, debounceTime1));
window.addEventListener("scroll", home4Animation);

home4AuOfficeCity.addEventListener("click", function () {
	home4AuOffice.classList.add("open");
});
home4AuOfficeClose.addEventListener("click", function () {
	home4AuOffice.classList.remove("open");
	home4UkOffice.classList.remove("open");
	home4MxOffice.classList.remove("open");
	home4ClOffice.classList.remove("open");
	home4CoOffice.classList.remove("open");
	home4BrOffice.classList.remove("open");
	home4CnOffice.classList.remove("open");
	home4InOffice.classList.remove("open");
});
home4UkOfficeCity.addEventListener("click", function () {
	home4UkOffice.classList.add("open");
});
home4UkOfficeClose.addEventListener("click", function () {
	home4AuOffice.classList.remove("open");
	home4UkOffice.classList.remove("open");
	home4MxOffice.classList.remove("open");
	home4ClOffice.classList.remove("open");
	home4CoOffice.classList.remove("open");
	home4BrOffice.classList.remove("open");
	home4CnOffice.classList.remove("open");
	home4InOffice.classList.remove("open");
});
home4MxOfficeCity.addEventListener("click", function () {
	home4MxOffice.classList.add("open");
});
home4MxOfficeClose.addEventListener("click", function () {
	home4AuOffice.classList.remove("open");
	home4UkOffice.classList.remove("open");
	home4MxOffice.classList.remove("open");
	home4ClOffice.classList.remove("open");
	home4CoOffice.classList.remove("open");
	home4BrOffice.classList.remove("open");
	home4CnOffice.classList.remove("open");
	home4InOffice.classList.remove("open");
});
home4CoOfficeCity.addEventListener("click", function () {
	home4CoOffice.classList.add("open");
});
home4CoOfficeClose.addEventListener("click", function () {
	home4AuOffice.classList.remove("open");
	home4UkOffice.classList.remove("open");
	home4MxOffice.classList.remove("open");
	home4ClOffice.classList.remove("open");
	home4CoOffice.classList.remove("open");
	home4BrOffice.classList.remove("open");
	home4CnOffice.classList.remove("open");
	home4InOffice.classList.remove("open");
});
home4ClOfficeCity.addEventListener("click", function () {
	home4ClOffice.classList.add("open");
});
home4ClOfficeClose.addEventListener("click", function () {
	home4AuOffice.classList.remove("open");
	home4UkOffice.classList.remove("open");
	home4MxOffice.classList.remove("open");
	home4ClOffice.classList.remove("open");
	home4CoOffice.classList.remove("open");
	home4BrOffice.classList.remove("open");
	home4CnOffice.classList.remove("open");
	home4InOffice.classList.remove("open");
});
home4BrOfficeCity.addEventListener("click", function () {
	home4BrOffice.classList.add("open");
});
home4BrOfficeClose.addEventListener("click", function () {
	home4AuOffice.classList.remove("open");
	home4UkOffice.classList.remove("open");
	home4MxOffice.classList.remove("open");
	home4ClOffice.classList.remove("open");
	home4CoOffice.classList.remove("open");
	home4BrOffice.classList.remove("open");
	home4CnOffice.classList.remove("open");
	home4InOffice.classList.remove("open");
});
home4CnOfficeCity.addEventListener("click", function () {
	home4CnOffice.classList.add("open");
});
home4CnOfficeClose.addEventListener("click", function () {
	home4AuOffice.classList.remove("open");
	home4UkOffice.classList.remove("open");
	home4MxOffice.classList.remove("open");
	home4ClOffice.classList.remove("open");
	home4CoOffice.classList.remove("open");
	home4BrOffice.classList.remove("open");
	home4CnOffice.classList.remove("open");
	home4InOffice.classList.remove("open");
});
home4InOfficeCity.addEventListener("click", function () {
	home4InOffice.classList.add("open");
});
home4InOfficeClose.addEventListener("click", function () {
	home4AuOffice.classList.remove("open");
	home4UkOffice.classList.remove("open");
	home4MxOffice.classList.remove("open");
	home4ClOffice.classList.remove("open");
	home4CoOffice.classList.remove("open");
	home4BrOffice.classList.remove("open");
	home4CnOffice.classList.remove("open");
	home4InOffice.classList.remove("open");
});

//Home 5
var swiper = new Swiper('.swiper-container', {
	
	pagination: '.swiper-pagination',
	centeredSlides: true,
	slidesPerView: 1,
	spaceBetween: 16,
	autoHeight: true,
	// init: false,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	breakpoints: {
		1024: {
			centeredSlides: false,
			slidesPerView: 2,
			spaceBetween: 32,
		},
	},
	onSlideChangeEnd: function (e) {
		swiper.update(true);
	}
});

const validateEmail = function (email) {
	const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

demoForm.addEventListener("submit", function (event) {
	event.preventDefault();
	demoButton.style.opacity = 0.3;
	demoButton.disabled = true;
	const nameField = document.querySelector("#name");
	const emailField = document.querySelector("#email");
	const phoneField = document.querySelector("#phone");
	const companyField = document.querySelector("#company");
	const referralField = document.querySelector("#referral");
	const commentsField = document.querySelector("#comments");
	
	nameField.classList.remove("is-invalid");
	emailField.classList.remove("is-invalid");
	phoneField.classList.remove("is-invalid");
	commentsField.classList.remove("is-invalid");

	if (commentsField.value.trim() == "") {
		commentsField.focus();
		commentsField.classList.add("is-invalid");
	}
	if (phoneField.value.trim() == "") {
		phoneField.focus();
		phoneField.classList.add("is-invalid");
	}

	if (emailField.value.trim() == "" || !validateEmail(emailField.value.trim())) {
		emailField.focus();
		emailField.classList.add("is-invalid");
	}

	if (nameField.value.trim() == "") {
		nameField.focus();
		nameField.classList.add("is-invalid");
	}
	
	if (nameField.value.trim() == "" || emailField.value.trim() == "" || !validateEmail(emailField.value.trim()) || phoneField.value.trim() == "" || commentsField.value.trim() == "") {
		demoButton.disabled = false;
		demoButton.style.opacity = 1;
		return false;
	}

	const item = {
    name: nameField.value.trim(),
		email: emailField.value.trim(),
		phone: phoneField.value.trim(),
		company: companyField.value.trim(),
		referral: referralField.value.trim(),
		comments: commentsField.value.trim(),
  };

	fetch("/api/demo", {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(item)
	})
		.then(response => {
			if(response.status == 200){
				demoForm.style.display = "none";
				demoFailed.style.display = "none";
				demoSuccess.style.display = "block";
			} else {
				demoForm.style.display = "flex";
				demoFailed.style.display = "block";
				demoSuccess.style.display = "none";
			}
			demoButton.style.opacity = 1;
			demoButton.disabled = false;
			return false;
		})
		.catch(error => {
			demoForm.style.display = "flex";
			demoFailed.style.display = "block";
			demoSuccess.style.display = "none";
			demoButton.style.opacity = 1;
			demoButton.disabled = false;
			return false;
		});
	demoButton.style.opacity = 1;
	demoButton.disabled = false;
	return false;
});